<template>
  <v-data-table
    :headers="headers"
    :items="computedBookings"
    class="elevation-1"
    :search.sync="search"
    :loading="loadingTable"
    :page.sync="pagination.pageNumber"
    :items-per-page.sync="pagination.perPage"
    :server-items-length="pagination.totalItems"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    }"
  >
    <template v-slot:[`item.createdAt`]="{ item }">
      <span>{{ item.createdAt | formatToHuman }}</span>
    </template>

    <template v-slot:top>
      <div class="tw-px-2 md:tw-px-6 tw-mb-4">
        <card-title icon="mdi-car" :is-image="false"
          >Booked Vehicles</card-title
        >
      </div>

      <div class="tw-px-2 md:tw-px-6 mt-9">
        <v-btn
          :outlined="tab === 'assignedTickets'"
          color="primary"
          class="mr-4"
          @click="tab = 'pendingTickets'"
          >Pending Tickets</v-btn
        >
        <v-btn
          :outlined="tab === 'pendingTickets'"
          color="primary"
          @click="tab = 'assignedTickets'"
          >Assigned Tickets</v-btn
        >
      </div>

      <v-toolbar flat color="white">
        <v-divider class="mx-6" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-toolbar>
      <v-dialog v-model="dialogView" max-width="800px">
        <v-card>
          <v-card-text>
            <v-container>
              <v-container class="text-center">
                <v-card-title>
                  <h3 class="primary--text">Vehicle & User Details</h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <h3 class="text-left grey--text pt-5">
                    Qualified For Assessment
                  </h3>
                </v-card-text>
                <v-card-text>
                  <ul class="text-left">
                    <v-row>
                      <v-col cols="12" md="6">
                        <li>
                          Insured’s Name:<span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'clientInfo.name')
                          }}</span>
                        </li>
                        <li>
                          Insured’s Phone Number:<span
                            class="pl-3 primary--text"
                            >{{
                              scanObject(viewItem, 'clientInfo.phoneNumber')
                            }}</span
                          >
                        </li>
                        <li>
                          Reg No:
                          <span class="pl-3 primary--text">{{
                            scanObject(
                              viewItem,
                              'vehicleInfo.registrationNumber'
                            )
                          }}</span>
                        </li>
                        <li>
                          Policy No:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.policyNumber')
                          }}</span>
                        </li>
                        <li>
                          Sum Assured:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.sumInsured')
                          }}</span>
                        </li>
                        <li>
                          Endorsement Number:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.endorsementNumber')
                          }}</span>
                        </li>
                      </v-col>
                      <v-col cols="12" md="6">
                        <li>
                          Policy Type:<span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.coverType')
                          }}</span>
                        </li>
                        <li>
                          Policy Description:<span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.description')
                          }}</span>
                        </li>
                        <li>
                          Policy Period from:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.effectiveFromDate')
                          }}</span>
                        </li>
                        <li>
                          Policy Period to:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.effectiveToDate')
                          }}</span>
                        </li>
                        <li>
                          Renewal Date:
                          <span class="pl-3 primary--text">{{
                            scanObject(viewItem, 'policyInfo.renewalDate')
                          }}</span>
                        </li>
                      </v-col>
                    </v-row>
                  </ul>
                </v-card-text>
              </v-container>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAssign" max-width="600px">
        <v-card>
          <v-container>
            <v-card-text class="mx-auto pt-10">
              <h3 color="primary">
                Assign the booked vehicles to the available internal assessors
              </h3>
            </v-card-text>
            <v-card-actions class="mx-auto px-12">
              <v-row class="mt-3">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="selectedInternalAssessor"
                    :items="internalAssessorTeam"
                    :loading="userLoading"
                    label="Search for An Internal Assessor"
                    prepend-icon="mdi-database-search"
                    chips
                    deletable-chips
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col class="my-5" cols="12">
                  <v-btn
                    class="secondary mb-5"
                    large
                    block
                    @click="submitInternalAssessor"
                  >
                    submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="recallTicketDialog" max-width="600px">
        <v-card class="mx-auto">
          <v-container>
            <v-card-text class="mx-auto pt-10 text-center">
              <h1 class="text-h5 mb-5 text--primary">Recall Ticket ?</h1>
              <p>
                {{
                  `Are you sure you want to recall this ticket you assigned to ${bookingInternalAssessor} ?`
                }}
              </p>
            </v-card-text>
            <v-card-actions class="mx-auto px-12">
              <v-row class="mt-3">
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    class="my-5"
                    outlined
                    large
                    block
                    @click="recallTicketDialog = false"
                  >
                    Cancel</v-btn
                  >
                </v-col>
                <v-col class="my-5" cols="6">
                  <v-btn
                    color="primary"
                    class="mb-5"
                    large
                    block
                    :loading="recallLoading"
                    @click="recallTicket('INTERNAL_ASSESSOR')"
                  >
                    Yes</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip v-if="item.status === 'VEHICLE_BOOKED'" color="primary">
        Booked
      </v-chip>
      <v-chip v-if="item.status === 'INT_ASSESSOR_ASSIGNED'" color="primary">
        Pending Inspection
      </v-chip>
      <v-chip
        v-if="item.status === 'CHECKLIST_FILLED'"
        color="accent"
        text-color="black"
      >
        Checklist
      </v-chip>
    </template>

    <template v-if="bookings.length >= 0" v-slot:[`item.actions`]="{ item }">
      <v-row>
        <v-col cols="12" md="2">
          <v-btn small text class="mr-3" color="secondary">
            <v-icon
              color="primary"
              :id="`viewMore${item.id}`"
              @click="viewMore(item)"
            >
              mdi-eye
            </v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="item.status === 'VEHICLE_BOOKED'" cols="12" md="8">
          <v-btn
            :id="`addChecklist${item.id}`"
            small
            class="ml-5"
            color="secondary"
            :to="{
              name: 'Checklist',
              query: { booking: JSON.stringify(item) },
            }"
          >
            Add Checklist
          </v-btn>
        </v-col>
        <v-col v-if="item.status === 'CHECKLIST_FILLED'" cols="12" md="8">
          <v-btn small class="ml-5" color="primary" @click="assignTicket(item)">
            Assign Ticket
          </v-btn>
        </v-col>
        <v-col v-if="item.status === 'INT_ASSESSOR_ASSIGNED'" cols="12" md="8">
          <v-btn
            small
            class="ml-5"
            color="secondary"
            @click="showRecallTicketDialog(item)"
          >
            Recall Ticket
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar top right v-model="snackbar" :color="snackbarColor">
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-data-table>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios'
import { formatToHuman } from '@/utils/time'
import CardTitle from '@/components/CardTitle'
import bookingsMixin from '@/mixins/bookingsMixin'
import store from '@/store'
export default {
  name: 'BookedVehicle',
  components: { CardTitle },
  mixins: [bookingsMixin],
  data: () => ({
    dialog: false,
    dialogView: false,
    dialogAssign: false,
    loadingTable: true,
    userLoading: false,
    isDescending: true,
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    selectedInternalAssessor: null,
    internalAssessorList: [],
    bookingId: '',
    bookingInternalAssessor: '',
    newObj: {
      attributes: '',
      username: '',
    },
    bookings: [],
    viewItemIndex: -1,
    viewItem: {
      id: '',
      clientInfo: '',
      createdAt: '',
      vehicleInfo: '',
      policyInfo: '',
      status: '',
    },
    fetchBookingsUrl:
      '/motor-assessment/api/booking?bookingRequestType=BOOKING_BY_STATUS&bookingStatus=VEHICLE_BOOKED&bookingStatus=CHECKLIST_FILLED&bookingStatus=INT_ASSESSOR_ASSIGNED',
  }),

  computed: {
    internalAssessorTeam() {
      return this.internalAssessorList.map((a) => a.attributes['value'])
    },
    pendingTickets: function () {
      return this.bookings.filter(
        (booking) => booking.status !== 'INT_ASSESSOR_ASSIGNED'
      )
    },

    assignedTickets: function () {
      return this.bookings.filter(
        (booking) => booking.status === 'INT_ASSESSOR_ASSIGNED'
      )
    },

    computedBookings: function () {
      if (this.tab === 'pendingTickets') return this.pendingTickets
      return this.assignedTickets
    },
  },
  created: function () {
    this.fetchBookings()
    this.sortBy = [false, false, false, false]
  },
  methods: {
    viewMore(item) {
      this.viewItemIndex = this.bookings.indexOf(item)
      this.viewItem = { ...item }
      this.dialogView = true
    },
    assignTicket(item) {
      this.dialogAssign = true
      this.userLoading = true
      this.bookingId = item.id
      const userPayload = {
        userPool: process.env.VUE_APP_USER_POOL_ID,
        userGroup: 'internalAssessor',
        limit: 60,
        region: store.getters['auth/authenticatedUser']['custom:region'],
      }
      axios
        .post('/motor-assessment/api/users/list', userPayload)
        .then((res) => {
          const users = res.data.data
          let newArr = users.map((user) => {
            this.newObj = {}
            this.newObj.attributes = user.attributes.find(
              (attribute) => attribute.name === 'custom:name'
            )
            this.newObj.username = user.username
            return this.newObj
          })

          for (let user of newArr) {
            this.internalAssessorList.push(user)
          }

          this.userLoading = false
        })
        .catch(() => {
          this.userLoading = false
        })
    },
    submitInternalAssessor() {
      const selectedName = this.internalAssessorList.filter(
        (internalAssessor) =>
          internalAssessor.attributes['value'] === this.selectedInternalAssessor
      )
      const usernameCode = selectedName[0].username
      axios
        .post(
          `/motor-assessment/api/internal-assessor/assign/${this.bookingId}?userId=${usernameCode}`
        )
        .then((res) => {
          this.snackbar = true
          this.snackbarMessage = res.data.msg
          this.snackbarColor = 'success'
          this.bookings = this.bookings.filter((i) => i.id !== this.bookingId)
          this.selectedInternalAssessor = null
          this.fetchBookings()
        })
        .catch(() => {
          this.snackbar = true
          this.snackbarMessage = 'An error occurred, please try again!'
          this.snackbarColor = 'error'
        })
      this.dialogAssign = false
    },
    showRecallTicketDialog(item) {
      this.bookingId = item.id
      this.bookingInternalAssessor = this.scanObject(
        item,
        'internalAssessor.name'
      )
      this.recallTicketDialog = true
    },
  },
  filters: {
    formatToHuman,
  },
}
</script>
